import walletConnect from '../../utils/walletConnect';

import { toggleModal } from '../modal/actions';

import { IJsonRpcRequest } from '@walletconnect/types';
import { AppThunk } from '../types';
import { GET_QRCODE, LOGIN_SUCCESS, LOGIN, LOGOUT, RESET_ERROR, AuthTypes } from './types';
import { locationTypes, DROP_LOCATION } from '../location/types';
import { callRequestHandler } from '../asset/utils';

import CONSTANTS from '../../config/constant';
import { WebErrorType, WebError, NetworkError, WEB_ERROR } from '../../utils/error';
import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from '../spinner/types';
import { DROP_SITE_TOKENS } from '../site/types';
import { DROP_PROFILE_DATA, POST_BUSINESS_PROFILE_DATA } from '../profile/types';
import { GlobalAccessToken } from '@aglive/frontend-core';
import { callAPI } from '../../utils/network';
import API from '../../config/api';
import { authLogout } from '../../utils/auth'
import { DeviceUUID } from 'device-uuid';
import WalletConnect from '@walletconnect/client';
import { getUserProfile } from '../profile/actions';

const {
  CONNECT,
  SESSION_UPDATE,
  CALL_REQUEST,
  DISCONNECT,
  WALLET_CONNECT,
} = CONSTANTS.WALLETCONNECT;

export function getQrcode(history: any, closeFunction?: (arg0: any) => void): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      // get randomId from server
      //      const res = await axios(API.GET.getQR, {
      //      headers: {
      //         'Accept': '*/*',
      //         'Accept-Encoding': 'gzip, deflate',
      //         'Access-Control-Request-Headers': 'access-control-allow-methods, access-control-allow-origin',
      //         'Access-Control-Request-Method': 'GET',
      //         'Connection': 'keep-alive',
      //         'Host': `${API.GET.socketURL}`,
      //         'Origin': 'https://dev-aglive.web.app'
      //      },
      //   });

      // check if there's a cached session in localstorage, if there is, remove session
      if (localStorage.getItem(WALLET_CONNECT)) {
        localStorage.removeItem(WALLET_CONNECT)
      }

      // initate session with WalletConnect
      dispatch(initWalletConnect(history)).then(connector => {
        dispatch({
          type: GET_QRCODE,
          payload: connector.uri
        });
  
        //connect to event 'connect' so that it will receive the payload after the mobile approve the session request
        connector.on(CONNECT, async (error: Error | null, payload: IJsonRpcRequest) => {
          if (error) throw new WebError("OFFLINE_ERROR");
          if (payload) {
            const hasMessageAndSignature = payload.params[0].accounts[1] && payload.params[0].accounts[2];
            if (hasMessageAndSignature) {
              try {
                let deviceId = localStorage.getItem(CONSTANTS.DEVICE_ID);
                if (!deviceId) {
                  deviceId = new DeviceUUID().get();
                  localStorage.setItem(CONSTANTS.DEVICE_ID, deviceId);
                }
                const data = { message: payload.params[0].accounts[1], signature: payload.params[0].accounts[2], deviceId };
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('state') && urlParams.get('client_id')) {
                  data['state'] = urlParams.get('state');
                  data['client_id'] = urlParams.get('client_id');
                }

                // Stores geolocation obtained from mobile (if it is provided in the payload)
                const geolocation: string | undefined = payload.params[0].accounts[3];
                if (geolocation) {
                  localStorage.setItem(
                    CONSTANTS.USER_GEOLOCATION_STORAGE_KEY,
                    geolocation,
                  );
                } else {
                  localStorage.removeItem(CONSTANTS.USER_GEOLOCATION_STORAGE_KEY);
                }

                const res = await callAPI({url: API.POST.authUser, method: 'POST', data: data});
                if (res && GlobalAccessToken.setTokenFromRes({ data: { ...res } })) {
                  if (res.mode === 'ceres tag integration' && urlParams.get('redirect_uri')) {
                    window.location.href = `${urlParams.get('redirect_uri')}?access_token=${res.accessToken}&state=${res.state}&token_type=access_token&expires_in=${res.accessTokenExpiration}`
                  } else {
                    dispatch({
                      type: LOGIN_SUCCESS,
                      payload: payload.params[0].accounts[0]
                    });
                  }
                }
                else {
                  console.log('GlobalAccessToken.setTokenFromRes(res) =>> fail')
                }
              } catch (error) {
                console.error(error);
              }
            } 
          }
        });
        if (closeFunction) {
          connector.on('close', e => {
            if (!navigator.onLine) {
              new NetworkError(e).sendErrortoSentry()
              closeFunction({
                title: WEB_ERROR.WALLETCONNECT_ERROR.title,
                message: WEB_ERROR.WALLETCONNECT_ERROR.details,
              });
            } else {
              closeFunction({
                title: WEB_ERROR.QR_EXPIRE.title,
                message: WEB_ERROR.QR_EXPIRE.details,
              });
            }
          });
        }
      })
      .catch(e => {
        const error = e as WebErrorType;
        dispatch(toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Try Again',
          CTAHandler: () => window.location.reload(),
        }));
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(toggleModal({
        status: 'failed',
        title: error.title,
        subtitle: error.message,
        button: 'Try Again',
        CTAHandler: () => window.location.reload(),
      }));
  }
  }
}

export function login(): AuthTypes {
  return {
    type: LOGIN,
  };
}

export function appThunkLogout(skipRevoke = false): AppThunk<Promise<boolean>> {
  return async (dispatch) => {
    await logout(dispatch, skipRevoke)
    return Promise.resolve(true);
  }
}

// This way we can call it outside a component
export const logout = async (dispatch: (arg0: AuthTypes | locationTypes | any) => void, skipRevoke = false) => {
  walletConnect.killSession();
  localStorage.removeItem(WALLET_CONNECT);
  if (skipRevoke === false) {
    await authLogout();
  }
  dispatch({ type: LOGOUT });
  sessionStorage.removeItem('persist:auth'); // HACK
  dispatch({ type: DROP_LOCATION });
  dispatch({ type: DROP_SITE_TOKENS });
  dispatch({ type: DROP_PROFILE_DATA });
}

export function resetError(): AuthTypes {
  return {
    type: RESET_ERROR,
  };
}

export function initWalletConnect(history: any): AppThunk<Promise<WalletConnect>> {
  return async (dispatch) => {
    try {
      const connector = await walletConnect.initWalletConnect();

      if (!connector) throw new WebError('WALLETCONNECT_ERROR', connector);
      
      connector.on(
        SESSION_UPDATE,
        (error: Error | null, payload: IJsonRpcRequest) => {
          if (error) throw new WebError("OFFLINE_ERROR", error);
        }
      );

      connector.on(DISCONNECT, (error: any | null, payload: any) => {
        if (error) {
          dispatch({ type: SPINNER_TOGGLE_OFF });

          dispatch(toggleModal({
            status: 'failed',
            title: 'Blockchain Error',
            subtitle: error.message,
          }));
        }
      });

      connector.on(
        CALL_REQUEST,
        (error: any | null, payload: IJsonRpcRequest) => {
          // if (error) throw error; // new Error(ERROR.OFFLINE);
          console.log("subscribeToEvents call_request->payload", payload, error);
          if (error) {
            callRequestHandler(dispatch, history)(new WebError('WALLETCONNECT_ERROR', String(error)), payload as any);
          } else {
            callRequestHandler(dispatch, history)(error, payload as any);
          }
        }
      );

      connector.on('error', e => {
        new NetworkError(e).sendErrortoSentry()
        dispatch(toggleModal({
          status: 'failed',
          title: WEB_ERROR.WALLETCONNECT_ERROR.title,
          subtitle: WEB_ERROR.WALLETCONNECT_ERROR.details,
          button: 'Reload',
          CTAHandler: () => window.location.reload(),
        }));
      });

      return Promise.resolve(connector);
    } catch (e) {
      if (e instanceof WebError) {
        callRequestHandler(dispatch, history)(e, undefined);
      } else {
        throw new NetworkError(e);
      }
    }
  };
}

export const submitInvitationCode = async (
  invitationCode: string,
  userId: string,
) => {
  try {
    const newActivity = {
      type: 'join_business',
      details: {
        invitationCode,
      },
    };
    const tokens = {
      tokens: [
        {
          type: 'business',
          activities: [newActivity],
        },
      ],
    };

    await callAPI({
      url: API.POST.createActivity,
      method: 'POST',
      data: tokens,
    });

    const token2 = {
      latestDetails: true,
      externalIds: [{userId: userId}],
      type: [CONSTANTS.ASSETTYPE.USER],
      status: ['exist'],
    };

    const userResponse = await callAPI({
      url: API.POST.getTokenbyExternalId,
      method: 'POST',
      data: token2,
    });

    const userProfile = userResponse[0]?.details;
    return userProfile;
  } catch (e) {
    console.error('invitationCodeError->', e);
    throw e;
  }
};

export const submitCodeHandler = (
  invitationCode: string,
  userId: string,
  history: any,
) => {
  return async (dispatch: (arg0: any) => void) => {
    try {
      if (!invitationCode.length || !userId) return;

      const {businessId, role} = await submitInvitationCode(
        invitationCode.trim(),
        userId,
      );

      const response = await callAPI({
        url: API.POST.getTokenbyExternalId,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          externalIds: [{agliveToken: businessId}],
          type: [],
        },
      });

      if (response) {
        const businessData = response[0]?.details;

        dispatch({
          type: POST_BUSINESS_PROFILE_DATA,
          payload: businessData,
        });

        history.push({
          pathname: '/private',
          state: {fromAuth: true, role: role},
        });
      }
    } catch (e) {
      console.error('error: ', e);
      const error = e as WebErrorType;
      if (error.code === 'E_NOT_FOUND') {
        dispatch(
          toggleModal({
            status: 'warning',
            title: 'Invalid',
            subtitle: 'Your invitation code is invalid',
            button: 'Try Again',
          }),
        );
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
      }
    }
  };
};

export function ssoAuthUser(
  authorizationCode: string,
  history: any,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const response = await callAPI({
        url: API.POST.ssoAuthUser,
        method: 'POST',
        data: {
          authorizationCode,
        },
      });
      if (response) {
        const {userId, signUp, accessToken} = response;
        GlobalAccessToken.setTokenFromRes({data: {accessToken}});
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Successfully authenticated from Google account',
            button: 'Close',
            CTAHandler: () => {
              dispatch({
                type: LOGIN_SUCCESS,
                payload: userId,
              });
              dispatch(getUserProfile(userId));
              if (signUp === true) {
                history.push({
                  pathname: '/sso-login',
                  state: {fromAuth: true},
                });
              } else if (signUp === false) {
                history.push({
                  pathname: '/private',
                  state: {fromAuth: true},
                });
              }
            },
          }),
        );
      }

      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      console.error('soo login error', error);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function sendEmailVerification(
  captchaCode: string,
  userEmail: string,
  history: any,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const response = await callAPI({
        url: API.POST.verifyEmail,
        method: 'POST',
        data: {
          email: userEmail,
          capchaCode: captchaCode,
        },
      });

      if (response?.errorStatus == 0) {
        dispatch(
          toggleModal({
            status: 'success',
            title: response?.title || 'Email Sent',
            subtitle: response?.message || '',
            button: 'Close',
            CTAHandler: () => {
              history.push({
                pathname: '/',
                state: {fromAuth: true},
              });
            },
          }),
        );
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: response?.title || 'Error occured when sending email',
            subtitle: response?.message || '',
          }),
        );
      }
      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      console.error('sending email error:', error);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error?.title || 'Error occured when sending email',
          subtitle: error?.message || '',
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function checkEmailVerificationCode(
  verificationCode: string,
  codeType: string,
  history: any,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const response = await callAPI({
        url: API.POST.checkVerificationCode,
        method: 'POST',
        data: {
          code: verificationCode,
          codeType: codeType,
        },
      });

      if(response.errorStatus == 0){
        dispatch({type: SPINNER_TOGGLE_OFF});
        return {isError: 0, response: response};
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: response?.title || 'Error occured',
            subtitle: response?.message || '',
            button: 'Close',
            CTAHandler: () => {
              dispatch({type: SPINNER_TOGGLE_OFF});
              history.push({
                pathname: '/',
              });
            },
          }),
        );
        return {isError: 1, response: response};
      }
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: 'Error processing Code',
          button: 'Close',
          CTAHandler: () => {
            dispatch({type: SPINNER_TOGGLE_OFF});
            history.push({
              pathname: '/',
            });
          },
        }),
      );
      return {isError: 1, response: error};
    }
  };
}

export function createUserEmailAccount(
  userEmail: string,
  userName: string,
  userContactNumber: string,
  password: string,
  verificationCode: string,
  history: any,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const response = await callAPI({
        url: API.POST.createEmailAccount,
        method: 'POST',
        data: {
          email: userEmail,        
          username: userName,
          mobileNumber: userContactNumber, 
          password: password,
          verificationCode: verificationCode,
        },
      });

      if (response?.errorStatus == 0) {
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Account Created',
            button: 'Close',
            CTAHandler: () => {
              history.push({
                pathname: '/',
              });
            },
          }),
        );
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: response?.title || 'Error occured when creating account',
            subtitle: response?.message || '',
          }),
        );
      }
      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      console.error('Error occured when creating account:', error);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error?.title || 'Error occured when creating account',
          subtitle: error?.message || '',
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function emailPasswordAuth(
  userEmail: string,
  userPassword: string,
  history: any,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const loginEmailResponse = await callAPI({
        url: API.POST.loginEmailAccount,
        method: 'POST',
        data: {
          email: userEmail,        
          password: userPassword,
        },
      });

      let authorizationCode = loginEmailResponse.authorizationCode.authorizationCode

      if(loginEmailResponse?.errorStatus == 0) {
        const response = await callAPI({
          url: API.POST.ssoAuthUser,
          method: 'POST',
          data: {
            authorizationCode,
          },
        });
        if (response) {
          const {userId, signUp, accessToken} = response;
          GlobalAccessToken.setTokenFromRes({data: {accessToken}});
          dispatch(
            toggleModal({
              status: 'success',
              title: 'Successfully authenticated',
              button: 'Close',
              CTAHandler: () => {
                dispatch({
                  type: LOGIN_SUCCESS,
                  payload: userId,
                });
                dispatch(getUserProfile(userId));
                if (signUp === true) {
                  history.push({
                    pathname: '/sso-login',
                    state: {fromAuth: true},
                  });
                } else if (signUp === false) {
                  history.push({
                    pathname: '/private',
                    state: {fromAuth: true},
                  });
                }
              },
            }),
          );
        }
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: loginEmailResponse?.title || 'Error Authenticating',
            subtitle: loginEmailResponse?.message || '',
          }),
        );
      }

      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      console.error('login error', error);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function requestForgotPassword(
  userEmail: string,
  history: any,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const response = await callAPI({
        url: API.POST.forgotPassword,
        method: 'POST',
        data: {
          email: userEmail,
        },
      });

      if (response?.errorStatus == 0) {
        dispatch(
          toggleModal({
            status: 'success',
            title: response?.title || 'Email Sent',
            subtitle: response?.message || '',
            button: 'Close',
            CTAHandler: () => {
              history.push({
                pathname: '/',
              });
            },
          }),
        );
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: response?.title || 'Error occured when sending email',
            subtitle: response?.message || '',
          }),
        );
      }
      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      console.error('sending email error:', error);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error?.title || 'Error occured when sending email',
          subtitle: error?.message || '',
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function resetPassword(
  userEmail: string,
  password: string,
  verificationCode: string,
  history: any,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const response = await callAPI({
        url: API.POST.resetPassword,
        method: 'POST',
        data: {
          email: userEmail,
          password: password,
          code: verificationCode,
        },
      });

      if (response?.errorStatus == 0) {
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Password Reset',
            button: 'Close',
            CTAHandler: () => {
              history.push({
                pathname: '/',
              });
            },
          }),
        );
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: response?.title || 'Error occured when reseting password',
            subtitle: response?.message || '',
          }),
        );
      }
      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      console.error('Error occured when reseting password:', error);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error?.title || 'Error occured when reseting password',
          subtitle: error?.message || '',
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}


export function verifyGoogleIdentitySSO(
  code: string,
  history: any,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const verifyGoogleResponse = await callAPI({
        url: API.POST.verifyGoogleIdentitySSO,
        method: 'POST',
        data: {
          code: code,
        },
      });
      //console.log("verifyGoogleResponse:")
      //console.log(verifyGoogleResponse)
      let authorizationCode = verifyGoogleResponse.authorizationCode.authorizationCode

      if(verifyGoogleResponse?.errorStatus == 0) {
        const response = await callAPI({
          url: API.POST.ssoAuthUser,
          method: 'POST',
          data: {
            authorizationCode,
          },
        });
        if (response) {
          const {userId, signUp, accessToken} = response;
          GlobalAccessToken.setTokenFromRes({data: {accessToken}});
          dispatch(
            toggleModal({
              status: 'success',
              title: 'Successfully authenticated',
              button: 'Close',
              CTAHandler: () => {
                dispatch({
                  type: LOGIN_SUCCESS,
                  payload: userId,
                });
                dispatch(getUserProfile(userId));
                if (signUp === true) {
                  history.push({
                    pathname: '/sso-login',
                    state: {fromAuth: true},
                  });
                } else if (signUp === false) {
                  history.push({
                    pathname: '/private',
                    state: {fromAuth: true},
                  });
                }
              },
            }),
          );
        }
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: verifyGoogleResponse?.title || 'Error Authenticating',
            subtitle: verifyGoogleResponse?.message || '',
          }),
        );
      }

      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      console.error('Error occured during verify Google Identity:', error);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error?.title || 'Error occured during verify Google Identity:',
          subtitle: error?.message || '',
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
