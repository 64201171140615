import React, {Suspense, useEffect, useMemo, useState} from 'react';
import {CASL} from '@aglive/frontend-core';
import CONSTANT from 'src/config/constant';
import {useAppSelector, useAppDispatch} from 'src/utils/hooks';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';

import {toggleModal} from './store/modal/actions';
import {appThunkLogout, initWalletConnect} from './store/auth/actions';

import {makeStyles} from '@material-ui/core/styles';
import {MODAL_TOGGLE_OFF} from './store/modal/types';
import {WebErrorType} from './utils/error';
import COLOR from './styled/colors';
import Grid from '@material-ui/core/Grid';
import Spinner from 'src/presentation/Spinner';
import MyModal from 'src/presentation/Modal';

import type {Message} from 'src/presentation/Modal';

const BusinessProfile = React.lazy(
  () => import('src/container/profile/BusinessProfile'),
);
const EditBusinessProfile = React.lazy(
  () => import('src/container/profile/EditBusinessProfile'),
);
const BrandProfile = React.lazy(
  () => import('src/container/brand/BrandLibrary/BrandLibrary'),
);
const CreateBrand = React.lazy(
  () => import('src/container/brand/BrandProfile/CreateBrand'),
);
const AssetInduction = React.lazy(
  () => import('src/container/asset/assetInduction'),
);
const AssetInductionLibrary = React.lazy(
  () => import('src/container/asset/AssetInductionLibrary'),
);
const ManageAsset = React.lazy(() => import('src/container/asset/ManageAsset'));
const ManageAssetByCSV = React.lazy(
  () => import('src/container/asset/ManageAssetByCSV'),
);
const AVPreSalesReport = React.lazy(
  () => import('src/container/verified/AVPreSalesReport'),
);
const CodeLibrary = React.lazy(() => import('src/container/code/CodeLibrary'));
const SecurityCodeLibrary = React.lazy(
  () =>
    import(
      'src/container/code/SecurityCode/SecurityCodeLibrary/SecurityCodeLibrary'
    ),
);
const UsedCodes = React.lazy(() => import('src/container/code/UsedCodes'));
// Asset Profile
const AssetProfileLibrary = React.lazy(
  () => import('src/container/assetProfile/AssetProfileLibrary'),
);
const CreateAssetProfile = React.lazy(
  () => import('src/container/assetProfile/CreateAssetProfile'),
);
const ViewAssetProfile = React.lazy(
  () => import('src/container/assetProfile/ViewAssetProfile'),
);
const ArchivedAssetProfiles = React.lazy(
  () => import('src/container/assetProfile/ArchivedAssetProfiles'),
);
const GenerateAssets = React.lazy(
  () => import('src/container/code/GenerateAssets'),
);
const ViewBrand = React.lazy(
  () => import('src/container/brand/BrandProfile/ViewBrand'),
);
const UserLibrary = React.lazy(() => import('src/container/add/UserLibrary'));
const MoveActivity = React.lazy(
  () => import('src/container/asset/MoveActivity'),
);
const CreateMove = React.lazy(() => import('src/container/asset/CreateMove'));

const CreateGroup = React.lazy(() =>
  import('src/container/group/CreateGroup').then((m) => ({
    default: m.CreateGroup,
  })),
);
const AddToGroup = React.lazy(() =>
  import('src/container/group/CreateGroup').then((m) => ({
    default: m.AddToGroup,
  })),
);
const RemoveFromGroup = React.lazy(() =>
  import('src/container/group/CreateGroup').then((m) => ({
    default: m.RemoveFromGroup,
  })),
);

const SiteLibrary = React.lazy(() => import('src/container/site/SiteLibrary'));
const CreateSite = React.lazy(() => import('src/container/site/CreateSite'));
const EditSite = React.lazy(() => import('src/container/site/EditSite'));
const ViewSite = React.lazy(() => import('src/container/site/ViewSite'));

const Sidebar = React.lazy(() => import('./presentation/Sidebar'));
const Auth = React.lazy(() => import('src/container/auth'));
const QRLogin = React.lazy(() => import('src/container/auth/QRLogin'));
const EmailLogin = React.lazy(() => import('src/container/auth/EmailLogin'));
const EmailAccountCreation = React.lazy(() => import('src/container/auth/EmailAccountCreation'));
const ForgotPassword = React.lazy(() => import('src/container/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('src/container/auth/ResetPassword'));
const SsoLogin = React.lazy(() => import('src/container/auth/SsoLogin'));

const VerifiedActions = React.lazy(
  () => import('src/container/verified/VerifiedActions'),
);
const AiJoining = React.lazy(() => import('src/container/verified/AiJoining'));
const ViewAnimalProfile = React.lazy(
  () => import('src/container/verified/ViewAnimalProfile'),
);
const ViewSireProfile = React.lazy(
  () => import('src/container/verified/ViewSireProfile'),
);
const EditSire = React.lazy(() => import('src/container/verified/EditSire'));
const AnimalProfile = React.lazy(
  () => import('src/container/verified/AnimalProfile'),
);
const Treatment = React.lazy(() => import('src/container/verified/Treatment'));
const Pregnancy = React.lazy(() => import('src/container/verified/Pregnancy'));
const AngusVerifiedDetails = React.lazy(
  () => import('src/container/verified/AngusVerifiedDetails'),
);
const OrderTags = React.lazy(() => import('src/container/verified/OrderTags'));
const RecordAnimals = React.lazy(
  () => import('src/container/verified/RecordAnimals'),
);
const MobDetails = React.lazy(
  () => import('src/container/verified/MobDetails'),
);
const EditAnimal = React.lazy(
  () => import('src/container/verified/EditAnimal'),
);
const RecordSires = React.lazy(
  () => import('src/container/verified/RecordSires'),
);
const SireGroups = React.lazy(
  () => import('src/container/verified/SireGroups'),
);
const SireGroupDetails = React.lazy(
  () => import('src/container/verified/SireGroupDetails'),
);
const EditGroupName = React.lazy(() =>
  import('src/container/verified/SireGroupDetails').then((m) => ({
    default: m.EditGroupName,
  })),
);
const AddSireToGroup = React.lazy(
  () => import('src/container/verified/AddSireToGroup'),
);
const AddAnimalToMob = React.lazy(
  () => import('src/container/verified/AddAnimalToMob'),
);
const Home = React.lazy(() => import('src/container/home'));
const UserProfile = React.lazy(
  () => import('src/container/profile/UserProfile'),
);
const PluginActive = React.lazy(() =>
  import('src/container/profile/Plugins').then((m) => ({
    default: m.PluginActive,
  })),
);
const PluginInactive = React.lazy(() =>
  import('src/container/profile/Plugins').then((m) => ({
    default: m.PluginInactive,
  })),
);
const ViewPlugin = React.lazy(() =>
  import('src/container/profile/Plugins').then((m) => ({
    default: m.ViewPlugin,
  })),
);
const EditPlugin = React.lazy(() =>
  import('src/container/profile/Plugins').then((m) => ({
    default: m.EditPlugin,
  })),
);
const PluginsLibrary = React.lazy(
  () => import('src/container/profile/PluginsLibrary'),
);
const CeresTagReport = React.lazy(
  () => import('src/container/reports/CeresTagReport'),
);
const TrackAnimal = React.lazy(() => import('src/container/map/TrackAnimal'));

const ManagementReport = React.lazy(
  () => import('src/container/reports/ManagementReport'),
);
const ToolBar = React.lazy(() => import('./presentation/ToolBar'));
const HealthSupport = React.lazy(() => import('src/container/HealthSupport'));
const MainLanding = React.lazy(
  () => import('src/container/promotion/MainLanding'),
);
const PromotionLibrary = React.lazy(
  () => import('src/container/promotion/PromotionLibrary'),
);
const CreateAssetPromotion = React.lazy(
  () => import('src/container/promotion/CreateAssetPromotion'),
);
const AssetLanding = React.lazy(
  () => import('src/container/promotion/AssetLanding'),
);
const ArchivedPromotionLibrary = React.lazy(
  () => import('src/container/promotion/ArchivedPromotionLibrary'),
);
const ViewAssetPromotion = React.lazy(
  () => import('src/container/promotion/ViewAssetPromotion'),
);
const CreateProductProfile = React.lazy(
  () => import('src/container/promotion/ProductProfile/CreateProductProfile'),
);
const CreateProductPromotion = React.lazy(
  () => import('src/container/promotion/CreateProductPromotion'),
);
const ViewProductPromotion = React.lazy(
  () => import('src/container/promotion/ViewProductPromotion'),
);
const ProductProfileLibrary = React.lazy(
  () => import('src/container/promotion/ProductProfileLibrary'),
);
const ArchivedProductProfileLibrary = React.lazy(
  () => import('src/container/promotion/ArchivedProductProfileLibrary'),
);
const CodeMainLanding = React.lazy(
  () => import('src/container/code/MainLanding/index'),
);
const EditProductProfile = React.lazy(
  () => import('src/container/promotion/ProductProfile/EditProductProfile'),
);
const ViewProductProfile = React.lazy(
  () => import('src/container/promotion/ProductProfile/ViewProductProfile'),
);
const EditBrand = React.lazy(
  () => import('src/container/brand/BrandProfile/EditBrand'),
);
const GenerateSecurityCode = React.lazy(
  () => import('src/container/code/SecurityCode/GenerateCodes'),
);
const UsedSecurityCodes = React.lazy(
  () => import('src/container/code/SecurityCode/UsedSecurityCodes'),
);
const ArchivedBrandLibrary = React.lazy(
  () => import('src/container/brand/ArchivedBrandLibrary/ArchivedBrandLibrary'),
);
const EditCode = React.lazy(
  () => import('src/container/code/SecurityCode/EditCodes/EditCode'),
);
const ActivitiesReport = React.lazy(
  () => import('src/container/reports/ActivitiesReport'),
);
const SingleUser = React.lazy(() => import('src/container/add/SingleUser'));
const ConsigneeLibrary = React.lazy(
  () => import('src/container/add/ConsigneeLibrary'),
);
const SingleConsignee = React.lazy(
  () => import('src/container/add/SingleConsignee'),
);
const CreateForm = React.lazy(() =>
  import('src/container/asset/FormTemplate').then((m) => ({
    default: m.CreateForm,
  })),
);
const DuplicateForm = React.lazy(() =>
  import('src/container/asset/FormTemplate').then((m) => ({
    default: m.DuplicateForm,
  })),
);
const EditForm = React.lazy(() =>
  import('src/container/asset/FormTemplate').then((m) => ({
    default: m.EditForm,
  })),
);
const ViewForm = React.lazy(() =>
  import('src/container/asset/FormTemplate').then((m) => ({
    default: m.ViewForm,
  })),
);
const FormLibrary = React.lazy(() => import('src/container/asset/FormLibrary'));
const ConsignmentLibrary = React.lazy(
  () => import('src/container/consignment/consignmentLibrary'),
);
const DuplicateConsignment = React.lazy(() =>
  import('src/container/consignment/consignment').then((m) => ({
    default: m.DuplicateConsignment,
  })),
);
const EditConsignment = React.lazy(() =>
  import('src/container/consignment/consignment').then((m) => ({
    default: m.EditConsignment,
  })),
);
const CreateConsignment = React.lazy(() =>
  import('src/container/consignment/consignment').then((m) => ({
    default: m.CreateConsignment,
  })),
);
const ViewConsignment = React.lazy(
  () => import('src/container/consignment/ViewConsignment'),
);
const AddCeresTag = React.lazy(() => import('src/container/add/AddCeresTag'));
const GroupLibrary = React.lazy(
  () => import('src/container/group/GroupLibrary'),
);
const ViewGroup = React.lazy(() =>
  import('src/container/group/Group').then((m) => ({default: m.ViewGroup})),
);
const EditGroup = React.lazy(() =>
  import('src/container/group/Group').then((m) => ({default: m.EditGroup})),
);
const SelectIndustryType = React.lazy(
  () => import('src/container/auth/SelectIndustryType'),
);
const CropReports = React.lazy(
  () => import('src/container/reports/CropReports'),
);
const ContactLibrary = React.lazy(
  () => import('src/container/add/contact/ContactLibary'),
);
const ContactDetails = React.lazy(
  () => import('src/container/add/contact/ContactDetails'),
);
const NewContact = React.lazy(
  () => import('src/container/add/contact/NewContact'),
);
const EnvdFormPage = React.lazy(
  () => import('src/container/form/envdb/EnvdFormPage'),
);

export default function RootRoute() {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const isSpinnerOpen = useAppSelector((state) => state.spinner.isOpen);
  const {isOpen: isModalOpen, ...modalState} = useAppSelector(
    (state) => state.modal,
  );
  const modalConfig: Message = modalState; // TS could not infer the correct type

  return (
    <Router>
      {/*
        A <Switch> looks through all its children <Route>
        elements and renders the first one whose path
        matches the current URL. Use a <Switch> any time
        you have multiple routes, but you want only one
        of them to render at a time
      */}
      <Switch>
        <Route exact path="/">
          <Suspense fallback={<Spinner isOpen={true} />}>
            <Auth />
          </Suspense>
        </Route>
        <Route exact path="/qr-login">
          <Suspense fallback={<Spinner isOpen={true} />}>
            <QRLogin />
          </Suspense>
        </Route>
        <Route exact path="/email-login">
          <Suspense fallback={<Spinner isOpen={true} />}>
            <EmailLogin />
          </Suspense>
        </Route>
        <Route exact path="/forgot-password">
          <Suspense fallback={<Spinner isOpen={true} />}>
            <ForgotPassword />
          </Suspense>
        </Route>
        <Route exact path="/reset-password">
          <Suspense fallback={<Spinner isOpen={true} />}>
            <ResetPassword />
          </Suspense>
        </Route>
        <Route exact path="/email-account-creation">
          <Suspense fallback={<Spinner isOpen={true} />}>
            <EmailAccountCreation />
          </Suspense>
        </Route>
        <Route exact path="/select-industry">
          <Suspense fallback={<Spinner isOpen={true} />}>
            <SelectIndustryType />
          </Suspense>
        </Route>
        <Route path="/sso-login">
          <Suspense fallback={<Spinner isOpen={true} />}>
            <SsoLogin />
          </Suspense>
        </Route>
        <Route path="/form/envd-b/:id">
          <Suspense fallback={<Spinner isOpen={true} />}>
            <EnvdFormPage />
          </Suspense>
        </Route>
        <PrivateRoute isLoggedIn={isLoggedIn} path="/private">
          <ProtectedPages />
        </PrivateRoute>
      </Switch>
      <MyModal
        isOpen={isModalOpen}
        closeHandler={() => dispatch({type: MODAL_TOGGLE_OFF})}
        message={modalConfig}
      />
      <Spinner isOpen={isSpinnerOpen} />
    </Router>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
  },
  angusRoot: {
    background: COLOR.AA_BLACK,
  },
  sidebar: {
    backgroundColor: theme.palette.secondary.main,
    minWidth: '200px',
  },
  content: {
    backgroundColor: theme.palette.secondary.main,
    margin: '5px 32px 0 32px',
    padding: '48px',
    borderRadius: '20px 20px 0 0',
    minHeight: '100vh',
    width: 'calc(100% - 64px)',
    flex: 1,
  },
  browserAlert: {
    backgroundColor: COLOR.GRAY_SOLID,
    padding: '15px 30px',
    width: '100%',
  },
}));

function ProtectedPages() {
  const businessProfileData = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const businessPlugins = useAppSelector((state) => state.user.plugins);

  const isPlants = businessProfileData.industryType === 'PLANTS';
  const isAnimals = businessProfileData.industryType === 'ANIMALS';
  const isProducts = businessProfileData.industryType === 'PRODUCTS';
  const isMackasShowcase = useMemo(
    () =>
      !!businessPlugins?.find(
        (p) =>
          p.status === 'activated' &&
          p.name === CONSTANT.MACKAS_SHOWCASE_PLUGIN,
      ),
    [businessPlugins],
  );

  const allowProductProfile = isMackasShowcase || isProducts;

  const isAngus = useAppSelector(
    // (state) => !!state.user.businessProfileData.angusProfile,
    (state) =>
      state.user.businessProfileData.industryType === 'ANIMALS' &&
      state.user.businessProfileData.businessType === 'Angus',
  );
  const classes = useStyles();
  const {path} = useRouteMatch();
  const currentURL = useLocation();

  // For managing user permissions
  const userRole = useAppSelector((state) => state.user.userProfileData?.role);
  const [loadedData, setLoadedData] = useState(false);
  const ability = CASL.useAbility(CASL.AbilityContext);

  useEffect(() => {
    // Update permissions based on user role
    if (userRole) {
      CASL.updateAbility(ability, userRole);
    }
    setLoadedData(true);
  }, [userRole, ability]);

  return (
    <Grid
      container
      wrap="nowrap"
      className={
        isAngus && currentURL.pathname.includes('/verified')
          ? classes.angusRoot
          : classes.root
      }>
      {/* sidebar on the left*/}
      <Suspense fallback={null}>
        <Sidebar key={`${ability.rules.length}`} />
      </Suspense>
      {/* main content */}
      <Grid item container xs={10} direction="column">
        <Suspense fallback={null}>
          <ToolBar />
        </Suspense>
        <Grid item container className={classes.content}>
          <Suspense fallback={<Spinner isOpen={true} />}>
            <Switch>
              <Route exact path={`${path}/verified`}>
                <VerifiedActions />
              </Route>
              <Route exact path={`${path}/verified/recordSires`}>
                <RecordSires />
              </Route>
              <Route exact path={`${path}/verified/recordSires/edit-sire`}>
                <EditSire />
              </Route>
              <Route exact path={`${path}/verified/aiJoining`}>
                <AiJoining />
              </Route>
              <Route exact path={`${path}/verified/sireGroups`}>
                <SireGroups />
              </Route>
              <Route exact path={`${path}/verified/sireGroups/details`}>
                <SireGroupDetails />
              </Route>
              <Route
                exact
                path={`${path}/verified/sireGroups/details/editGroupName`}>
                <EditGroupName />
              </Route>
              <Route
                exact
                path={`${path}/verified/sireGroups/details/add-sire`}>
                <AddSireToGroup />
              </Route>
              <Route exact path={`${path}/verified/recordAnimals`}>
                <RecordAnimals />
              </Route>
              <Route exact path={`${path}/verified/recordAnimals/mob-details`}>
                <MobDetails />
              </Route>
              <Route
                exact
                path={`${path}/verified/recordAnimals/mob-details/add-animal`}>
                <AddAnimalToMob />
              </Route>
              <Route exact path={`${path}/verified/recordAnimals/edit-animal`}>
                <EditAnimal />
              </Route>
              <Route exact path={`${path}/verified/orderTags`}>
                <OrderTags />
              </Route>
              <Route exact path={`${path}/verified/animalProfile`}>
                <AnimalProfile />
              </Route>
              <Route exact path={`${path}/verified/animalProfile/treatment`}>
                <Treatment />
              </Route>
              <Route exact path={`${path}/verified/animalProfile/pregnancy`}>
                <Pregnancy />
              </Route>
              <Route
                exact
                path={`${path}/verified/animalProfile/angus-verified`}>
                <AngusVerifiedDetails />
              </Route>
              <Route exact path={`${path}/verified/animalProfile/view-animal`}>
                <ViewAnimalProfile />
              </Route>
              <Route exact path={`${path}/verified/animalProfile/view-sire`}>
                <ViewSireProfile />
              </Route>
              <Route exact path={`${path}/verified/avpresale`}>
                <AVPreSalesReport />
              </Route>
              <Route exact path={`${path}/profile/users`}>
                <UserProfile view={true} />
              </Route>
              <Route exact path={`${path}/profile/users/edit`}>
                <UserProfile />
              </Route>
              <Route exact path={`${path}/profile/businessProfile`}>
                <BusinessProfile />
              </Route>
              {ability.can('update', 'business') && (
                <Route exact path={`${path}/profile/businessProfile/edit`}>
                  <EditBusinessProfile />
                </Route>
              )}
              {(ability.can('update', 'establishment') ||
                ability.can('update', 'location')) && (
                <Route
                  exact
                  path={`${path}/profile/businessProfile/editEstablishment`}>
                  <EditBusinessProfile isEstablishment={true} />
                </Route>
              )}
              <Route exact path={`${path}/profile/brand`}>
                <BrandProfile />
              </Route>
              <Route exact path={`${path}/profile/brand/archived`}>
                <ArchivedBrandLibrary />
              </Route>
              {ability.can('create', 'brand') && (
                <Route exact path={`${path}/profile/brand/new`}>
                  <CreateBrand />
                </Route>
              )}
              {ability.can('update', 'brand') && (
                <Route exact path={`${path}/profile/brand/edit/:id`}>
                  <EditBrand />
                </Route>
              )}
              <Route exact path={`${path}/profile/brand/view/:id`}>
                <ViewBrand />
              </Route>
              {CONSTANT.ENABLE_PLUGIN && (
                <Route exact path={`${path}/profile/plugins`}>
                  <PluginActive />
                </Route>
              )}
              <Route exact path={`${path}/profile/plugins/inactive`}>
                <PluginInactive />
              </Route>
              <Route exact path={`${path}/profile/plugins/add`}>
                <PluginsLibrary />
              </Route>
              <Route exact path={`${path}/profile/plugins/view/:name`}>
                <ViewPlugin />
              </Route>
              <Route exact path={`${path}/profile/plugins/edit/:name`}>
                <EditPlugin />
              </Route>
              {ability.can('update', 'asset') && (
                <Route exact path={`${path}/activity/manage`}>
                  <ManageAsset />
                </Route>
              )}
              <Route exact path={`${path}/activity/move`}>
                <MoveActivity />
              </Route>
              <Route exact path={`${path}/activity/move/create`}>
                <CreateMove />
              </Route>
              {ability.can('update', 'asset') && (
                <Route exact path={`${path}/activity/csv`}>
                  <ManageAssetByCSV />
                </Route>
              )}
              <Route exact path={`${path}/activity/group`}>
                <GroupLibrary />
              </Route>
              {ability.can('update', 'group') && (
                <Route exact path={`${path}/activity/group/new`}>
                  <CreateGroup />
                </Route>
              )}

              <Route exact path={`${path}/activity/group/view/:id`}>
                <ViewGroup />
              </Route>

              {ability.can('update', 'group') && (
                <Route exact path={`${path}/activity/group/edit/:id`}>
                  <EditGroup />
                </Route>
              )}

              {ability.can('update', 'group') && (
                <Route exact path={`${path}/activity/group/add/:gid/`}>
                  <AddToGroup type="add" />
                </Route>
              )}
              {ability.can('update', 'group') && (
                <Route exact path={`${path}/activity/group/remove/:gid/`}>
                  <RemoveFromGroup type="remove" />
                </Route>
              )}
              <Route exact path={`${path}/activity/consignment`}>
                <ConsignmentLibrary />
              </Route>
              {ability.can('create', 'consignment') && (
                <Route exact path={`${path}/activity/consignment/new`}>
                  <CreateConsignment />
                </Route>
              )}
              {ability.can('update', 'consignment') && (
                <Route exact path={`${path}/activity/consignment/edit/:docId`}>
                  <EditConsignment />
                </Route>
              )}
              {ability.can('create', 'consignment') && (
                <Route
                  exact
                  path={`${path}/activity/consignment/duplicate/:docId`}>
                  <DuplicateConsignment />
                </Route>
              )}
              <Route exact path={`${path}/activity/consignment/view/:id`}>
                <ViewConsignment />
              </Route>

              <Route exact path={`${path}/register/asset`}>
                {!isPlants ? <AssetInductionLibrary /> : <Redirect to={path} />}
              </Route>
              <Route exact path={`${path}/register/asset/new`}>
                {!isPlants ? <AssetInduction /> : <Redirect to={path} />}
              </Route>
              <Route exact path={`${path}/register/site`}>
                <SiteLibrary />
              </Route>
              {ability.can('create', 'site') && (
                <Route exact path={`${path}/register/site/new`}>
                  <CreateSite />
                </Route>
              )}
              <Route exact path={`${path}/register/site/:agliveToken`}>
                <ViewSite />
              </Route>
              {ability.can('update', 'site') && (
                <Route exact path={`${path}/register/site/edit/:agliveToken`}>
                  <EditSite />
                </Route>
              )}
              <Route exact path={`${path}/codes`}>
                <CodeMainLanding />
              </Route>
              <Route exact path={`${path}/codes/securityCodes`}>
                <SecurityCodeLibrary />
              </Route>
              <Route exact path={`${path}/codes/securityCodes/new`}>
                <GenerateSecurityCode />
              </Route>
              <Route exact path={`${path}/codes/securityCodes/used`}>
                <UsedSecurityCodes />
              </Route>
              <Route exact path={`${path}/codes/securityCodes/edit/:entryId`}>
                <EditCode />
              </Route>
              <Route exact path={`${path}/codes/generate`}>
                <CodeLibrary />
              </Route>
              <Route exact path={`${path}/codes/generate/new`}>
                <GenerateAssets />
              </Route>
              <Route exact path={`${path}/codes/generate/used/:docId?`}>
                <UsedCodes />
              </Route>
              <Route exact path={`${path}/codes/promotionCodes`}>
                {isProducts ? <PromotionLibrary /> : <Redirect to={path} />}
              </Route>
              <Route exact path={`${path}/codes/promotionCodes/new`}>
                {isProducts ? (
                  <CreateProductPromotion />
                ) : (
                  <Redirect to={path} />
                )}
              </Route>
              <Route exact path={`${path}/codes/promotionCodes/edit/:id`}>
                {isProducts ? (
                  <CreateProductPromotion />
                ) : (
                  <Redirect to={path} />
                )}
              </Route>
              <Route exact path={`${path}/codes/promotionCodes/view/:id`}>
                {isProducts ? <ViewProductPromotion /> : <Redirect to={path} />}
              </Route>
              <Route exact path={`${path}/codes/promotionCodes/archived`}>
                {isProducts ? (
                  <ArchivedPromotionLibrary />
                ) : (
                  <Redirect to={path} />
                )}
              </Route>
              <Route exact path={`${path}/products`}>
                <MainLanding actionButton />
              </Route>
              <Route exact path={`${path}/products/productProfile`}>
                {allowProductProfile ? (
                  <ProductProfileLibrary />
                ) : (
                  <Redirect to={path} />
                )}
              </Route>
              <Route exact path={`${path}/products/productProfile/new`}>
                {allowProductProfile ? (
                  <CreateProductProfile />
                ) : (
                  <Redirect to={path} />
                )}
              </Route>
              <Route exact path={`${path}/products/productProfile/view/:id`}>
                {allowProductProfile ? (
                  <ViewProductProfile />
                ) : (
                  <Redirect to={path} />
                )}
              </Route>
              <Route exact path={`${path}/products/productProfile/edit/:id`}>
                {allowProductProfile ? (
                  <EditProductProfile />
                ) : (
                  <Redirect to={path} />
                )}
              </Route>
              <Route exact path={`${path}/products/productProfile/archived`}>
                {allowProductProfile ? (
                  <ArchivedProductProfileLibrary />
                ) : (
                  <Redirect to={path} />
                )}
              </Route>
              <Route exact path={`${path}/assets/assetProfile`}>
                <AssetProfileLibrary />
              </Route>
              <Route exact path={`${path}/assets/assetProfile/new`}>
                <CreateAssetProfile />
              </Route>
              <Route exact path={`${path}/assets/assetProfile/view/:id`}>
                <ViewAssetProfile />
              </Route>
              <Route exact path={`${path}/assets/assetProfile/edit/:id`}>
                <CreateAssetProfile />
              </Route>
              <Route exact path={`${path}/assets/assetProfile/archived`}>
                <ArchivedAssetProfiles />
              </Route>
              {ability.can('read', 'user') && (
                <Route exact path={`${path}/add/users`}>
                  <UserLibrary />
                </Route>
              )}
              {ability.can('create', 'site') && (
                <Route exact path={`${path}/add/users/:id`}>
                  <SingleUser key="EditUser" type="edit" />
                </Route>
              )}
              <Route exact path={`${path}/add/users/view/:id`}>
                <SingleUser key="ViewUser" type="view" />
              </Route>
              <Route exact path={`${path}/add/formTemplate`}>
                <FormLibrary />
              </Route>
              {ability.can('create', 'form') && (
                <Route exact path={`${path}/add/formTemplate/new`}>
                  <CreateForm />
                </Route>
              )}
              {ability.can('update', 'form') && (
                <Route exact path={`${path}/add/formTemplate/edit/:docId`}>
                  <EditForm />
                </Route>
              )}
              <Route exact path={`${path}/add/formTemplate/view/:docId`}>
                <ViewForm />
              </Route>
              {ability.can('create', 'form') && (
                <Route exact path={`${path}/add/formTemplate/duplicate/:docId`}>
                  <DuplicateForm />
                </Route>
              )}
              <Route exact path={`${path}/add/consignees`}>
                <ConsigneeLibrary />
              </Route>
              {ability.can('create', 'consignee') && (
                <Route exact path={`${path}/add/consignees/new`}>
                  <SingleConsignee key="AddConsignee" />
                </Route>
              )}
              {ability.can('update', 'consignee') && (
                <Route exact path={`${path}/add/consignees/:id`}>
                  <SingleConsignee key="EditConsignee" />
                </Route>
              )}
              <Route exact path={`${path}/add/consignees/view/:id`}>
                <SingleConsignee key="ViewConsignee" view={true} />
              </Route>
              <Route exact path={`${path}/add/contacts`}>
                <ContactLibrary />
              </Route>
              <Route exact path={`${path}/add/contacts/view/:id`}>
                <ContactDetails key="ViewContactDetails" type="view" />
              </Route>
              {ability.can('update', 'contact') && (
                <Route exact path={`${path}/add/contacts/edit/:id`}>
                  <ContactDetails key="EditContactDetails" type="edit" />
                </Route>
              )}
              {ability.can('update', 'contact') && (
                <Route exact path={`${path}/add/contacts/new`}>
                  <NewContact />
                </Route>
              )}
              <Route exact path={`${path}/profile/map`}>
                <TrackAnimal />
              </Route>
              <Route exact path={`${path}/report/crops`}>
                <CropReports />
              </Route>
              <Route exact path={`${path}/report/ceresTagReport`}>
                <CeresTagReport />
              </Route>
              <Route exact path={`${path}/report/:type`}>
                <ManagementReport />
              </Route>
              <Route exact path={`${path}/healthSupport`}>
                <HealthSupport />
              </Route>
              <Route exact path={`${path}/assets`}>
                {isAnimals ? <AssetLanding /> : <Redirect to={path} />}
              </Route>
              <Route exact path={`${path}/assets/promotions`}>
                {isAnimals ? <PromotionLibrary /> : <Redirect to={path} />}
              </Route>
              <Route exact path={`${path}/assets/promotions/new`}>
                {isAnimals ? <CreateAssetPromotion /> : <Redirect to={path} />}
              </Route>
              <Route exact path={`${path}/assets/promotions/edit/:id`}>
                {isAnimals ? <CreateAssetPromotion /> : <Redirect to={path} />}
              </Route>
              <Route exact path={`${path}/assets/promotions/view/:id`}>
                {isAnimals ? <ViewAssetPromotion /> : <Redirect to={path} />}
              </Route>
              <Route exact path={`${path}/assets/promotions/archived`}>
                {isAnimals ? (
                  <ArchivedPromotionLibrary />
                ) : (
                  <Redirect to={path} />
                )}
              </Route>
              {/* Hidden path for testing */}
              <Route exact path={`${path}/dev-test/report/activities`}>
                <ActivitiesReport />
              </Route>
              <Route exact path={`${path}/add/cerestag`}>
                <AddCeresTag />
              </Route>
              <Route exact path={`${path}/`}>
                <Home />
              </Route>
              {userRole && loadedData && !ability.can('read', 'dashboard') && (
                <Redirect to={`${path}/profile/businessProfile`} />
              )}
            </Switch>
          </Suspense>
        </Grid>
      </Grid>
    </Grid>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({children, isLoggedIn, ...rest}) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(initWalletConnect(history)).catch((e) => {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Logout',
          CTAHandler: () => dispatch(appThunkLogout()),
        }),
      );
    });
  }, [dispatch, history]);

  return (
    <Route
      {...rest}
      render={() => (isLoggedIn ? children : <Redirect to="/" />)}
    />
  );
}
